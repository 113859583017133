<script>
import Validator from "./CMCourseValidator"

export default {
  props: {
    formErrors: Object
  },
  
  render() {
    return this.$scopedSlots.default({
      validationBeforeSend: this.validationBeforeSend,
    })
  },

  methods: {
    validationBeforeSend(payload) {
      new Validator(payload.data, this.formErrors, payload.examData).validate()
      if(!this.formErrors.isValid()) {
        this.$notify({text:'Invalid fields', type: 'error'})
        return
      }
      this.$emit('validated', {...payload, validator: this.formErrors})
    },
  }
}
</script>